<template>
  <div class="orderPay">
    <popup v-model="showpay" position="bottom" :style="{ maxHeight: '50%' }">
      <div class="pay-way">
        <div class="pay-title">
          <span>支付方式</span>
        </div>
        <cell-group>
          <div v-for="(item, index) in payType" :key="index">
            <template>
              <cell clickable is-link @click="goPay(item)">
                <template slot="title">
                  <img :src="item.image.url" alt="" class="pay-icon">
                  <span class="custom-title">{{ item.name }}</span>
                </template> 
              </cell>
            </template>
          </div>
        </cell-group>
      </div>
    </popup>
  </div>
</template>

<script>
import Setting from "@/api/setting";
import { Cell, CellGroup, Popup } from "vant";
export default {
  components: {
    Cell,
    CellGroup,
    Popup
  },
  props: ["key_id","recharge_id","card_type"],
  data() {
    return {
      showpay: false,
      payType: [] // 支付方式
    };
  },
  mounted() {
    this.getPayType();
  },
  methods: {
    async getPayType() {
      const pay = ['unionpay', 'wxpay', 'fuiou', 'normal_allinpay'];
      const res = await Setting.getUserPay();
      if (res.code == 200) {
        if (res.data.length > 0) {
          for (let i in res.data) {
            if (pay.indexOf(res.data[i].payment_code)>-1) {
              if (this.isWeixin_status) {
                res.data[i].name = '微信支付';
              } else {
                res.data[i].name = '支付宝支付';
              }
            }
          }
          this.payType = res.data;
          this.payType.push({
            payment_code: 'pos',
            name: '刷卡支付',
            image: {
              url: 'https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1609150168.png'
            }
          })
        }
      }
    },
    goPay(item) {
      let qrcode_id = localStorage.getItem('qrcode_id');
      this.$router.push({
        path: "authentication",
        query: {
          cardIdx: this.$route.query.cardIdx,
          key_id: this.key_id,
          recharge_id: this.recharge_id,
          type: item.payment_code,
          card_type: this.card_type,
          qrcode_id: qrcode_id ? qrcode_id:"" 
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.pay-icon {
  width: 13px;
  // height: 13px;
}
.custom-title {
  margin-left: 5px;
}


.pay-way {
  border-radius: 8px;
  overflow: auto;
  height: 100%;

  .stored-value {
    // height: 70px;
    padding: 0 10px;
    .discounts-item {
      padding: 0 10px;
      .discounts-item-con {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 3px;
        padding: 6px 10px;
        background: #fdfdfd;
        border: 1px solid #dfdfdf;

        .discounts-item-con-check {
          position: absolute;
          left: 10px;
          top: 0;
        }
      }

      .sw-active {
        color: rgba(0, 149, 255, 1);
        background: rgba(238, 245, 255, 1);
        border: 1px solid rgba(0, 149, 255, 1);
      }
    }
    .stored-value-gifts {
      height: 100px;
      background: antiquewhite;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 12px;
      .stored-value-gifts-opt {
        width: 100%;
        height: 100%;
        overflow: auto;
      }
      span {
        margin-right: 5px;
        font-size: 12px;
      }
      .marginBtm {
        margin-bottom: 3px;
      }
      .coupon-option {
        padding-left: 20px;
      }
    }

    .margin-Btm {
      padding-bottom: 8px;
    }
    .submitPay {
      height: 50px;
      display: flex;
      .paymony {
        flex: 2;
        text-align: center;
        .mony {
          font-size: 18px;
        }
      }
      .submitStored {
        flex: 8;
        display: flex;
        span {
          margin-right: 5px;
        }
        .wxpay {
          flex: 1;
          margin-right: 5px;
        }
        .zfbpay {
          flex: 1;
        }
      }
    }
  }
}

.pay-title {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

</style>