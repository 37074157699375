<template>
  <div class="storedValue">

    <div class="vip-card" v-if="!teamCardId">
      <div class="oilCard-infor">
        <div class="header-img">
          <img src="@static/vip/touxiang.png" alt="">
        </div>
        <div class="name-type">
          <span>{{ Info.card_info.name }}</span>
          <span>{{ Info.card_info.des }}</span>
        </div>
        <div class="storedPrice">
          <span>{{ Info.balance }}</span>
          <span>元</span>
        </div>
      </div>
      <div class="vip-card-num">{{ Info.card_num }}</div>
    </div>
    <div class="selectstored-option">
      <div v-for="(item, index) in moneyArr" :key="index" @click="setMoney(item, index)" class="stored-option"
        :class="selectIdx == index ? 'active' : ''">
        <p>+{{ item.charge_money }}元</p>
        <p class="icon-money" v-if="item.money_status == 1 && item.money">送{{ item.money }}元</p>
        <!-- <p v-if="item.point_status == 1 && item.point">送{{ item.point }}积分</p>
        <template v-if="item.coupon_status == 1 && item.coupon_ids">
          <p v-for="(card, idx) in item.coupon_data" :key="idx">{{ card.title }}</p>
        </template> -->
      </div>
    </div>
    <div class="content-box" v-if="selectIdx > -1">
      <ol class="styled-list">
        <li v-if="now_recharge_item.money_status === '1' && now_recharge_item.money != ''">赠送 {{ now_recharge_item.money }}
          元 余额</li>
        <li v-if="now_recharge_item.point_status === '1'">赠送 {{ now_recharge_item.point }} 积分</li>
        <li v-if="now_recharge_item.coupon_status === '1'">赠送 {{ now_recharge_item.coupon_data.length }} 张 优惠券</li>
        <li v-if="now_recharge_item.good_name">赠送 {{ now_recharge_item.good_name }} </li>

        <!--每升减-->
        <li v-if="now_recharge_item.is_unify === '1'">
          <div v-for="(item, index) in now_recharge_item.liter_good_money" :key="index">
            <div v-for="(child, idx) in item.itemArr" :key="idx">
              <span v-if="child.money">{{ child.name }}每升减{{ child.money }}元</span>
            </div>
          </div>
        </li>

        <!--锁油价-->
        <li v-if="now_recharge_item.is_lock === '1'">
          <div v-for="(item, index) in now_recharge_item.lock_good_money" :key="index">
            <div v-for="(child, idx) in item.itemArr" :key="idx">
              <span v-if="child.money">{{child.name}}每升{{ child.money }}元</span>
            </div>
          </div>
        </li>

        <!--首单优惠-->
        <li v-if="now_recharge_item.first_discount === '1'">
          <span>{{now_recharge_item.first_pay_discount === '100' ? '首单享受免单优惠':'首单享受'+(10-(Number(now_recharge_item.first_pay_discount)/10))+'折'}}</span>
        </li>
      </ol>
    </div>
    <div class="goPay" @click="goMoney">立即充值</div>
    <selectPayType ref="PayType" :card_type="card_type" :key_id="key_id" :recharge_id="recharge_id"></selectPayType>
  </div>
</template>

<script>
import selectPayType from "./selectPayType"
import CreditShop from "@/api/creditshop";
import OilCard from "@/api/oilCard";

export default {
  components: {
    selectPayType
  },
  data() {
    return {
      recharge_id: "", //充值id
      key_id: "", // 充值项
      selectIdx: -1,
      cardIdx: null,
      moneyId: null,
      moneyArr: [],
      Info: {
        card_info: {}
      },
      now_recharge_item: {}, // 当前充值优惠
      teamCardId: null,
      card_type: 1
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.hasOwnProperty('id')) {
        this.teamCardId = this.$route.query.id;
        this.cardIdx = this.$route.query.cardIdx;
        this.getSetMoney(3, this.teamCardId);
        this.card_type = 2
      } else {
        this.cardIdx = this.$route.query.cardIdx;
        this.getInfo();
      }
    })
  },
  methods: {
    goMoney() {
      if (this.selectIdx == '-1') return this.$toast("请选择充值类型");
      this.$refs.PayType.showpay = true;
    },
    // 设置充值优惠显示
    setMoney(item, idx) {
      this.selectIdx = idx;
      this.moneyId = idx;
      this.key_id = idx;
      this.now_recharge_item = item; // 设置当前优惠内容
      console.log(item);
    },
    async getInfo() {
      try {
        const res = await OilCard.getCardInfo(this.cardIdx);
        if (res.code == 200) {
          console.log(res.data)
          this.Info = res.data;
          this.getSetMoney(1, this.Info.card_info.id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSetMoney(type, id) {
      try {
        const res = await CreditShop.storedInfor(type, id);
        if (res.code == 200) {
          this.moneyArr = res.data.rule;
          this.recharge_id = res.data.id;
        } else {
          throw (res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.storedValue {
  height: 100vh;
  background: #fff;
  padding: 15px 10px;

  .vip-card {
    margin-bottom: 20px;
    width: 100%;
    height: 135px;
    border-radius: 5px;
    padding: 20px 18px;
    background-image: url("../../../../public/static/images/vip/cardbg.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    font-size: 12px;
    box-shadow: 1px 1px 3px 2px #bdbaba;

    .oilCard-infor {
      display: flex;
      position: relative;

      .header-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;

        img {
          width: 100%;
        }
      }

      .name-type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span:nth-child(1) {
          font-size: 16px;
        }
      }

      .storedPrice {
        position: absolute;
        right: 0px;
        height: 40px;
        line-height: 40px;

        span:nth-child(1) {
          font-size: 28px;
          margin-right: 8px;
          font-weight: bold;
        }
      }
    }
  }

  .van-cell {
    padding: 14px 0;
  }

  .van-cell:not(:last-child)::after {
    left: 0;
  }

  .content-box {
    width: 100%;
    min-height: 1em;
    padding: 10px;
    box-sizing: border-box;
    background-color: #fafafa;
    border-radius: 10px; //
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 18px;
    line-height: 1.5;

    .styled-list {
      color: #666; // 列表项的文字颜色
      font-size: 16px; // 列表项的文字大小
      list-style-type: decimal; // 列表项标记的类型
      padding-left: 30px; // 列表项的左内边距，增加这个是为了给列表项标记留出空间

      li {
        margin-bottom: 10px; // 列表项之间的间距
      }
    }
  }

  .selectstored-option {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-right: -12px;
    .icon-money {
      font-size: 14px;
      font-weight: 550;
      margin-top: 10px;
    }
    .stored-option {
      width: 110px;
      // min-height: 120px;
      margin-bottom: 15px;
      border-radius: 5px;
      border: 1px solid #D9D9D9;
      text-align: center;
      padding: 15px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 12px;

      p:nth-child(1) {
        font-size: 18px;
        color: rgb(252, 14, 14);
        font-weight: bold;
      }
    }

    .active {
      border: 1px solid #4E7AFF;
      color: #4E7AFF;
      background: #E6ECFF;

      p:nth-child(1) {
        color: #4E7AFF;
      }
    }
  }

  .goPay {
    position: absolute;
    bottom: 50px;
    width: 340px;
    left: 50%;
    margin-left: -170px;
    height: 40px;
    background: rgba(75, 124, 245, 1);
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    box-shadow: 1px 1px 3px 2px #bdbaba;
  }
}</style>
